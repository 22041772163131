@use "styles/colors";
@use "styles/texts";

$bottomNavBarHeight: 4.5rem;

.bottomNavBar {
  background: colors.$background-light;
  width: 100%;
  height: $bottomNavBarHeight;

  svg * {
    transition: fill 0.3s 0.1s;
  }
  > .tab {
    text-align: center;
    transition: all 0.3s;
    height: 100%;

    &.searchBtn {
      height: 50px;
      transform: translateY(calc(-1 * $bottomNavBarHeight / 3));
    }

    .button {
      width: 100%;
      height: 100%;
      color: colors.$bottom-bar-text;
      text-transform: none;
      font: texts.$subtitle-s;

      &.selected {
        color: colors.$text-white;
      }

      &.searchBtn {
        background: colors.$primary-gradient;
        width: 50px;
        height: 50px;
        padding: 0;
        min-width: 0;
        border-radius: 50%;
      }
      > .img{
        opacity: 0.5;
        &.selected {
          opacity: 1;
        }
      }
    }
  }
}
