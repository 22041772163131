@use "styles/colors";

.appInstallBtn {
  position: relative;
  display: flex;
  justify-content: center;
  transition: all 1s;

  .progressWrapper {
    position: absolute;
    transition: all 0.5s;
    pointer-events: none;
  }

  .ctaBtn {
    transition: all 1s;
    width: 100%;
  }

  .originalPrice {
    color: colors.$text-light;
    text-decoration: line-through;
  }
}
