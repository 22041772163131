@use "styles/colors";
@use "styles/config";

.coverPhoto {
  background: colors.$cards-background no-repeat center center / cover;
  border-radius: 12px;
  width: 100%;
  aspect-ratio: config.$banner-aspect-ratio;

  &.default {
    height: calc(100vw * (9 / 16));
    margin-bottom: -1rem;
    z-index: -1;
    position: sticky;
    top: 0;
    min-height: 275px;
    border-radius: 0;
  }

  &.collection {
    width: calc(100% - 2 * 1.5rem);
    height: 234px;
  }

  &.card {
    height: 200px;
  }
}

.header {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(23, 19, 44, 0) 0%,
    rgba(23, 19, 44, 0.9) 100%
  );
  border-radius: 12px;
  white-space: break-spaces;
}
