@use "styles/colors";

$thumbWidth: 80vw;

.screenshotGallery {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  scroll-snap-type: x mandatory;

  > .img {
    display: inline-flex;
    border-radius: 0.5rem;

    scroll-snap-align: center;
    scroll-snap-stop: always;

    max-width: $thumbWidth;
    max-height: calc($thumbWidth * (9 / 16));
  }
}

.fullScreenshotGallery {
  background: colors.$background-dark;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99999;

  .backBtn {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 999999;
  }

  > .images {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;

    scroll-snap-type: x mandatory;
    height: 100%;

    .img {
      display: inline-block;
      scroll-snap-align: center;
      scroll-snap-stop: always;

      width: calc(100vw - 36px);
      background: no-repeat center center;
      background-size: contain;
      border-radius: 0.5rem;
    }
  }
}
