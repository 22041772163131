/*******************/
//  SF - Simple Flex
/*******************/
// Attribute selectors
[sf] {
  display: flex;
}
[sf*="inline"] {
  display: inline-flex;
}
[sf*="col"] {
  flex-direction: column;
}
[sf*="wrap"] {
  flex-wrap: wrap;
}
[sf*="t"] {
  align-items: flex-start;
}
[sf*="m"] {
  align-items: center;
}
[sf*="b"] {
  align-items: flex-end;
}
[sf*="l"] {
  justify-content: flex-start;
}
[sf*="c"] {
  justify-content: center;
}
[sf*="r"] {
  justify-content: flex-end;
}
[sf*="sb"] {
  justify-content: space-between;
}

[sf="tsb"] {
  align-items: flex-start;
  justify-content: space-between;
}
[sf="msb"] {
  align-items: center;
  justify-content: space-between;
}
[sf="bsb"] {
  align-items: flex-end;
  justify-content: space-between;
}

[sf*="sa"] {
  justify-content: space-around;
}

/*******************/
//     Columns
/*******************/
@for $i from 1 through 12 {
  [col="#{$i}"] {
    flex: $i;
  }
}

/*******************/
//  Spacing
/*******************/
$spacing: (
  none: 0rem,
  xxs: 0.1333rem,
  xs: 0.333rem,
  sm: 0.666rem,
  md: 1rem,
  lg: 1.333rem,
  xl: 1.666rem,
  xxl: 2rem,
);

$sides: top, right, bottom, left;
$properties: padding, margin;

@each $property in $properties {
  @each $size, $val in $spacing {
    [#{$property}="all-#{$size}"] {
      #{$property}: $val;
    }
    [#{$property}*="tb-#{$size}"] {
      #{$property}-top: $val;
      #{$property}-bottom: $val;
    }
    [#{$property}*="lr-#{$size}"] {
      #{$property}-left: $val;
      #{$property}-right: $val;
    }

    [#{$property}-children="all-#{$size}"] {
      > *:not(:last-child) {
        #{$property}: $val;
      }
    }
    [#{$property}-children*="tb-#{$size}"] {
      > *:not(:last-child) {
        #{$property}-top: $val;
        #{$property}-bottom: $val;
      }
    }
    [#{$property}-children*="lr-#{$size}"] {
      > *:not(:last-child) {
        #{$property}-left: $val;
        #{$property}-right: $val;
      }
    }

    @each $side in $sides {
      [#{$property}*="#{$side}-#{$size}"] {
        #{$property}-#{$side}: $val;
      }
      [#{$property}-children*="#{$side}-#{$size}"] {
        > *:not(:last-child) {
          #{$property}-#{$side}: $val;
        }
      }
    }
  }
}
