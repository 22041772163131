@use "styles/colors.scss";

.settingsMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;

  .wash {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(21, 18, 35, 0.8);
  }

  .menu {
    width: 280px;
    padding: 48px 24px 24px 24px;
    background: colors.$primary-gradient;
    color: colors.$text-white;
    gap: 64px;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .separator {
      width: 100%;
      height: 1px;
      background: colors.$white10;
    }
  }
}
