@use "styles/colors";

.wrapper {
  background: colors.$cards-background;
  border-radius: 14px;
  position: relative;
  min-height: 150px;
}

.readMore {
  background: colors.$bottom-bar-light-gradient;
  height: 72px;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-radius: 14px;
}
