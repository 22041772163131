@use "styles/colors";
@use "styles/texts";

.collection {
  &.charts {
    .podium {
      text-align: center;
      height: 100;

      > * {
        height: 10rem;
        background: colors.$white50;
      }
    }

    .chartList {
      background: colors.$cards-background;
      border-radius: 1rem;
      padding: 0.375rem 0;

      .chartRank {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50%;
        background: colors.$secondary;
        color: colors.$text-white;

        font-size: 12px;
        font-weight: bold;
      }

      > *:not(:last-child) {
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: calc(100% - 2rem);
          border-bottom: 2px solid colors.$secondary;
          bottom: 0;
          left: 1rem;
        }
      }
    }
  }

  .appCards {
    //
    &.horizontal {
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;

      scroll-snap-type: x mandatory;
      scroll-snap-stop: always;

      padding: 0 1.333rem;

      > * {
        display: inline-flex;
        width: 86vw;
        max-width: 340px;

        scroll-snap-align: center;
        scroll-snap-stop: always;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}
