@use "styles/colors";
@use "styles/texts";

div.MuiTabs-root {
  min-height: auto;

  .MuiTabs-scroller {
    .MuiTabs-flexContainer {
      // border-bottom: 2px solid colors.$background-light;
      border-bottom: 2px solid colors.$cards-background;
      gap: 24px;
      justify-content: space-between;

      .MuiTab-root {
        font: texts.$subtitle-m;
        text-transform: none;
        padding: 0;
        min-width: auto;
        min-height: auto;
        height: 30px;
        color: colors.$text-dark;
        // background: rgba(255, 255, 0, 0.2);

        &.Mui-selected {
          color: colors.$text-white;
          // background: rgba(255, 0, 0, 0.2);
        }
      }
    }

    .MuiTabs-indicator {
      background: colors.$primary-gradient;
      top: 29px;
      bottom: auto;
      height: 3px;
      border-radius: 1.5px;
    }
  }
}

.MuiButtonBase-root.MuiButton-root {
  &:hover {
    background-color: unset;
  }
}
