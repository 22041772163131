@use "styles/colors";
.eventCardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: colors.$cards-background;
  border-radius: 12px;
  .eventCard {
    width: 100%;
    position: relative;
    height: 175px;
    border-radius: 12px;
    .coverPhoto {
      height: 100%;
      border-radius: inherit;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 138px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
      z-index: 1;
      border-radius: inherit;
    }

    .eventBody {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      padding: 0 14px 14px 14px;
      z-index: 2;
      .eventInfo {
        .eventText {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          .eventTitle,
          .desc {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
          }
          .desc {
            -webkit-line-clamp: 2;
          }
          .eventTitle {
            -webkit-line-clamp: 1;
          }
        }
      }
      .eventBadge,
      .endTime {
        display: flex;
        align-self: flex-end;
        width: fit-content;
        padding: 4px 6px 4px 4px;
        gap: 2px;
        border-radius: 5px;
      }
      .eventBadge {
        background: colors.$purple;
        display: inline-flex;
        margin-bottom: 4px;
      }
      .endTime {
        align-self: flex-end;
        background: colors.$black50;
        margin-top: 14px;
      }
    }
  }
  .eventCard.onlyTopBorderRadius {
    border-radius: 12px 12px 0px 0px;
  }
}
