@use "styles/colors";
@use "styles/texts";

.ctaBtn {
  position: relative;
  display: inline-flex;
  text-align: center;
  background: colors.$primary-gradient;
  color: colors.$text-white !important;
  font: texts.$subtitle-m;

  align-items: center;
  justify-content: center;
  text-transform: none !important;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(1);
  }

  &.isDisabled {
    pointer-events: none;
    color: colors.$bottom-bar-text !important;
    background: colors.$secondary;
  }

  &.small {
    font: texts.$button-s;
    height: 28px;
    line-height: 2rem;
    border-radius: 1rem;
    padding: 0 1rem;
    gap: 6px;

    &.iconOnly {
      padding: 0;
      width: 2rem;
    }
  }

  &.large {
    font: texts.$button-l;
    height: 42px;
    line-height: 42px;
    padding: 0 2rem;
    border-radius: 21px;
    gap: 10px;

    &.iconOnly {
      padding: 0;
      width: 3rem;
    }
  }

  &.wide {
    width: 100%;
  }
}
