@use "sass:color";
@use "styles/colors";
@use "styles/texts";

.cardPrice {
  .price {
    color: colors.$text-white;
    font: texts.$subtitle-s;
  }

  .discountPercent {
    color: colors.$text-white;
    font: texts.$caption;
    background-color: colors.$positive-green;
    border-radius: 4px;
    padding: 1px 4px;
  }

  .originalPrice {
    color: colors.$text-medium;
    font: texts.$subtitle-s;
    text-decoration: line-through;
  }
}
