@use "styles/colors";
@use "styles/texts";

.formInput {
  flex: 1;
  background: colors.$cards-background;
  border: none;
  border-radius: 10px;
  padding: 15px 1rem;
  outline: none;
  color: colors.$text-white;
  font: texts.$subtitle-m;

  &::placeholder {
    color: colors.$text-light;
  }
}
