@use "styles/colors";
@use "styles/texts";

.tabLabel {
  position: relative;
  background: red;

  // Dumb hack to stop the text from chaning size when selected
  .label {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);

    text-align: center;
    transition: all 0.2s;

    &.skeleton {
      transform: translateX(-50%) scale(1, 0.8);
    }
  }
}
