@use "styles/colors";
@use "styles/config";

.appIapPage {
  $stickyHeaderHeight: config.$app-info-header-height +
    config.$app-info-stats-height;

  > * {
    // Make sure all sections have a background so they will hide the cover photo under them
    background-color: colors.$background-medium;
  }

  .backBtn {
    background: none;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 9999;
  }


  .header{
    border-radius: 1rem 1rem 0 0;
  }

  .coverPhoto {
    height: calc(100vw * (9 / 16));
    background: colors.$cards-background no-repeat center center / cover;
    margin-bottom: -1rem;
    z-index: -1;
    position: sticky;
    top: 0;
  }

  .appIapIcon{
    align-self: center;
    margin-top: -32px
  }

  .coverPhotoOverlay{
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .appIapContentRoot {
    width: 100%;
    border-radius: 1.5rem 1.5rem 0 0;
    padding: 0 24px 24px 24px;
  }
  .appIapSubtitle{
    text-align: center;
    align-self: center;
    line-height: 24px;
  }
  //
  //.header {
  //  background: linear-gradient(
  //    180deg,
  //    colors.$background-dark 0,
  //    colors.$background-medium 100%
  //  );
  //
  //  border-radius: 1rem 1rem 0 0;
  //  transition: border-radius 0.2s;
  //
  //  position: sticky;
  //  top: 0;
  //  z-index: 1;
  //
  //  &.top {
  //    border-radius: 0;
  //  }
  //}
  //
  //.tabs {
  //  position: sticky;
  //  top: $stickyHeaderHeight;
  //  z-index: 1;
  //}
  //
  //.tabContent {
  //  $wrappersHeight: $stickyHeaderHeight + config.$app-info-install-btn-height;
  //  min-height: calc(100vh - $wrappersHeight - $stickyHeaderHeight + 7.5rem);
  //}
  //
  //.developerInfoField {
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //}

  .appIapItemSmall{
    width: 100%;
    min-height: 260px;
  }

  .appIapItemSmallImage {
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: none;
  }
}

.appIapItemLarge{
  width: 100%;
}

.iapCardLargeItemContent{
  width: 100%;
  padding: 14px;
}

.largeBanner{
  width: 100%;
  height: 128px;
  background: colors.$background-dark;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.iapItemPrePurchaseModalRoot{
  padding: 0;
}

.appIapItemModalSmallImage {
  width: 100%;
  max-height: 128px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
}

// modal ui

.wrapper {
  //flex-grow: 1;
}



