.square {
  width: 100%;
  bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  &.first {
    height: 129px;
    background: linear-gradient(180deg, #6756f9 0%, #5841ee 100%);
  }
  &.second {
    height: 105px;
    background: linear-gradient(180deg, #5b74f5 0%, #3c59f2 100%);
  }
  &.third {
    height: 85px;
    background: linear-gradient(180deg, #467fee 0%, #2a6ac9 100%);
  }
}

.squareContent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trapezoidOuterMask {
  width: 100%;
  overflow: hidden;
  position: relative;
  &.first {
    height: 15px;
    background-image: linear-gradient(180deg, #8d7cfd 0%, #7c68fe 100%);
    clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
  }
  &.second {
    height: 13px;
  }
  &.third {
    height: 11px;
  }
}

.trapezoidInnerMask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  width: 100%;
  &.second {
    right: -6px;
    height: 16px;
    transform: skew(-51deg, 0deg);
  }
  &.third {
    left: -7px;
    height: 11px;
    transform: skew(51deg, 0deg);
  }
}

.trapezoidContent {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  &.second {
    height: 14px;
    background-image: linear-gradient(180deg, #8497ff 0%, #6a81f9 100%);
  }
  &.third {
    height: 11px;
    background-image: linear-gradient(180deg, #5f97ea 0%, #4c90f2 100%);
  }
}
