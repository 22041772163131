$title-l: 600 28px "Open Sans";
$subtitle-l: 600 16px "Open Sans";
$subtitle-m: 600 14px "Open Sans";
$subtitle-s: 600 12px "Open Sans";
$body-s: 400 12px "Open Sans";
$caption-underline: 600 10px "Open Sans";
$button-l: 600 14px "Open Sans";
$button-s: 600 12px "Open Sans";
$caption: 600 10px "Open Sans";
$subtitle-s-bold: 700 12px "Open Sans";
$subtitle-l-bold: 700 16px "Open Sans";
$button-s-underline: 600 12px "Open Sans";
$body-m: 400 14px "Open Sans";
$body-l: 400 16px "Open Sans";
$title-m: 700 22px "Open Sans";
$title-s: 600 20px "Open Sans";
$title-s-bold: 700 20px "Open Sans";
$subtitle-m-bold: 700 14px "Open Sans";
$w-title: 700 38px "Poppins";
