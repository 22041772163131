@use "sass:color";
@use "styles/colors";

.pageHeader {
  position: sticky;
  top: 0;
  z-index: 1;

  .backBtn {
    display: flex;
  }

  .titleSmall {
    margin-bottom: 1px;
  }

  // Default Varient
  &.default {
    background: linear-gradient(
      180deg,
      colors.$background-dark 0%,
      colors.$background-dark 90%,
      color.change(colors.$background-dark, $alpha: 0) 100%
    );
  }

  // Used in discover & search
  &.large {
    background: linear-gradient(
      180deg,
      colors.$background-dark 0%,
      colors.$background-medium 92%,
      color.change(colors.$background-medium, $alpha: 0) 100%
    );
  }

  // Full gradient used in play page
  &.fullGradient {
    background: linear-gradient(
      180deg,
      colors.$background-dark 0%,
      color.change(colors.$background-dark, $alpha: 0) 100%
    );
    height: 200px;
  }
  .searchBtn {
    width: 22px;
    height: 22px;
    min-width: 0;
    box-sizing: content-box;
    padding-right: 0;
  }
}
