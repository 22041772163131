$black0: rgba(0, 0, 0, 0.0);
$white0: rgba(255, 255, 255, 0.0);
$black10: rgba(0, 0, 0, 0.1);
$white10: rgba(255, 255, 255, 0.1);
$black20: rgba(0, 0, 0, 0.2);
$white20: rgba(255, 255, 255, 0.2);
$black30: rgba(0, 0, 0, 0.3);
$white30: rgba(255, 255, 255, 0.3);
$black40: rgba(0, 0, 0, 0.4);
$white40: rgba(255, 255, 255, 0.4);
$black50: rgba(0, 0, 0, 0.5);
$white50: rgba(255, 255, 255, 0.5);
$black60: rgba(0, 0, 0, 0.6);
$white60: rgba(255, 255, 255, 0.6);
$black70: rgba(0, 0, 0, 0.7);
$white70: rgba(255, 255, 255, 0.7);
$black80: rgba(0, 0, 0, 0.8);
$white80: rgba(255, 255, 255, 0.8);
$black90: rgba(0, 0, 0, 0.9);
$white90: rgba(255, 255, 255, 0.9);
$black100: rgba(0, 0, 0, 1.0);
$white100: rgba(255, 255, 255, 1.0);
$primary: #644df0;
$secondary: #3a2f6d;
$cards-background: #2d2552;
$background-dark: #17132c;
$background-light: #261f47;
$text-white: #ffffff;
$text-light: #d5ceff;
$text-medium: #9b96bb;
$text-dark: #8580a0;
$pink: #dc6488;
$blue: #3a84ef;
$dark-blue: #4c66f3;
$purple: #6054e6;
$yellow: #fcd23c;
$bottom-bar-text: #746ea0;
$primary-gradient: linear-gradient(180deg, #6877ff 0%,#5e4df0 100%);
$shadow-cards: #d9d9d9;
$shadow-small-items: #d9d9d9;
$special-gradient: linear-gradient(180deg, #b499ff 0%,#7c99ff 48.4375%);
$background-radial-gradient: radial-gradient(#292052 0%,#17132c 100%);
$background-medium: #1f193c;
$bottom-bar-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,#17132c 44.27083432674408%,#17132c 100%);
$bottom-bar-light-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,#2d2552 44.27083432674408%,#2d2552 100%);
$overlay-gradient-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
$chip-shadow: #d9d9d9;
$positive-green: #12a771;
$negative-red: #fc6476;
$shade: #000000;
$top-bar-gradient: linear-gradient(180deg, #17132c 0%,#1f193c 82.8125%,rgba(0, 0, 0, 0) 100%);