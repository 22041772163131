@use "styles/texts";
@use "styles/colors";

// Global typography styling
h1,
h2,
h3,
h4,
h5,
h6 {
  color: colors.$text-white;
  margin: 0;
}
h1 {
  font: texts.$title-l; // Title Large
}
h2 {
  font: texts.$subtitle-l-bold; // Subtitle Large Bold
}
h3 {
  font: texts.$subtitle-l; // Subtitle Large
}
h4 {
  font: texts.$subtitle-m; // Subtitle Medium
}
h5 {
  font: texts.$subtitle-s; // Subtitle small
}
h6 {
  font: texts.$subtitle-s-bold; // Subtitle small Selected
}

// App Layout and scrolling
html,
body,
#root,
#root > .page-content,
#root > .page-content > .animatedRouteWrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
}

#root > .page-content {
  flex: 1;
  background: colors.$background-radial-gradient;
}

#root > .page-content > .animatedRouteWrapper {
  overflow-x: hidden;
  overflow-y: scroll;

  &.withoutScroll {
    overflow-y: hidden;
  }
}

* {
  /* removing highlight when clicking links and interactive elements */
  -webkit-tap-highlight-color: transparent;

  // Disable text selection
  -webkit-user-select: none;
  user-select: none;

  // Disable link dragging
  -webkit-user-drag: none;

  // Disable scrollbars
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// General
a,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

small {
  font-size: 0.75em;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

pre {
  color: white;
  font-family: Consolas, monospace;
  font-size: 0.8em;
  overflow: auto;
  background: black;
  padding: 1rem;
}

// Fix for blurry Lottie animations
// https://stackoverflow.com/questions/60258766/lottie-animated-svg-very-pixelated-blurry-in-safari-but-not-chrome-ff
.lf-player-container svg {
  transform: unset !important;
}

.bottom-overlay {
  &::after {
    content: "";
    position: sticky;
    display: block;
    bottom: 0;
    width: 100%;
    height: 56px;
    background: linear-gradient(to top, #17132c 0%, transparent 100%);
    pointer-events: none;
  }
}
