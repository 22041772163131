@use "styles/colors";

.wrapper {
  flex-grow: 1;
}

.content {
  padding: 1.5rem 1.5rem 2.125rem 1.5rem;
  border-radius: 1.333rem 1.333rem 0 0;
  outline: none;
  overflow: auto;
  background-color: colors.$cards-background;
  box-shadow: 0 0 1rem 1px colors.$black50;
  width: 100%;
}

.contentAfterOpen {
  animation: popUp 0.2s;
}

.contentBeforeClose {
  animation: popDown 0.2s;
}

@keyframes popUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.overlay {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: colors.$black80;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.overlayAfterOpen {
  animation: fadeIn 0.4s;
}

.overlayBeforeClose {
  animation: fadeOut 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
