.horizontal {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;

  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;

  padding: 0 1.333rem;

  > * {
    display: inline-flex;
    width: 86vw;
    max-width: 340px;

    scroll-snap-align: center;
    scroll-snap-stop: always;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
