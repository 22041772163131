@use "sass:color";
@use "styles/colors";

$subTitleMargin: 12px !important;

.expandButton {
  background: colors.$cards-background !important;
  padding: 6px 14px;
  margin-top: #{$subTitleMargin};
  > p {
    line-height: 14px;
    color: colors.$text-light;
  }
}

.title {
  margin-bottom: $subTitleMargin;
  line-height: 22px;
}

.gameInfoContent {
  &.contentCollapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
  }
  .contentTitle {
    line-height: 22px;
  }
  .whatsNew {
    margin-top: 1.5rem;
  }
  .contentBody {
    color: colors.$text-light;
    line-height: 22px;
  }
}
