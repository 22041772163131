@use "styles/colors";
@use "styles/config";

.installBtn {
  height: config.$app-info-install-btn-height;
  background: colors.$bottom-bar-gradient;
  position: sticky;
  bottom: 0;
  z-index: 9;
}
