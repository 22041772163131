@use "styles/colors";
@use "styles/texts";
@use "styles/config";

.appCard {
  display: flex;
  flex-direction: column;
  position: relative;
  background: colors.$cards-background;
  border-radius: 12px;
  padding: 14px;

  &.partnerLabel::after {
    content: "P";
    background: colors.$dark-blue;
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 20px;
    text-align: center;
  }
  &.videoCard {
    padding: 0;
    .infoGroup {
      padding: 14px;
    }
  }

  .video {
    width: 100%;
    aspect-ratio: config.$banner-aspect-ratio;
    border-radius: 8px 8px 0 0;
    border: none;
    background: colors.$background-dark;
    overflow: hidden;

    &.skeleton {
      background: unset;
    }

    video {
      object-fit: cover;
      width: 100%;
    }
  }

  .banner {
    width: 100%;
    aspect-ratio: config.$banner-aspect-ratio;
    background: colors.$background-dark;
    border-radius: 8px;
    overflow: hidden;

    &.skeleton {
      background: unset;
    }

    img {
      background: colors.$background-dark;
      width: 100%;
    }
  }

  .infoGroup {
    > *:not(:last-child) {
      margin-right: 14px;
    }

    .info {
      flex: 1;
      min-width: 0;

      > *:not(:last-child) {
        margin-right: 14px;
      }

      .titleGroup {
        flex: 1;
        min-width: 0;

        .appName {
          min-width: 0;
        }
      }
    }
  }

  .promotedBadge {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9999;
  }
}
