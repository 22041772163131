@use "sass:color";
@use "styles/colors";
@use "styles/texts";

.rating {
  font: texts.$caption;
  color: colors.$text-medium;
}

.topChartRating {
  color: colors.$text-white;
}
