@use "styles/colors";

.wrapper {
  flex-grow: 1;
}

.content {
  overflow: auto;
  width: 100%;
  margin: 0 24px;
  outline: none;
}

.overlay {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$black80;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.overlayAfterOpen {
  animation: fadeIn 0.4s;
}

.overlayBeforeClose {
  animation: fadeOut 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.closeButton {
  margin-bottom: 12px;
}

.modalBody {
  background-color: colors.$cards-background;
  border-radius: 12px;
  .coverPhoto {
    height: auto;
    border-radius: 12px 12px 0 0;
  }
  .info {
    padding: 1.125rem 0.875rem;
    .badges {
      width: 100%;
      margin-bottom: 0.875rem;
      .eventBadge,
      .endTime {
        display: flex;
        align-self: flex-end;
        width: fit-content;
        padding: 4px 6px 4px 4px;
        gap: 2px;
        border-radius: 5px;
      }
      .eventBadge {
        background: colors.$purple;
        display: inline-flex;
      }
      .endTime {
        align-self: flex-end;
        background: colors.$secondary;
      }
    }
    .eventTitle {
      line-height: 22px;
      margin-bottom: 4px;
    }
    .desc {
      line-height: 22px;
      color: colors.$text-light;
    }
  }

  .divider {
    width: 100%;
    border: 1px colors.$secondary solid;
  }
}
