@use "styles/colors";
@use "styles/config";

.content {
  outline: none;
  width: 100%;
  border-radius: 12px;

  &.fullScreen{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.contentAfterOpen {
  //padding: 0;
  //width: 100%;
  //height:100%;
  //animation: popUp 0.2s;
  //max-width: 500px;
}

.contentBeforeClose {
  //animation: popDown 0.2s;
}

@keyframes popUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

//.overlay {
//  position: absolute;
//  top: 0;
//  left: 0;
//
//  &.fullScreen{
//    position: absolute;
//    top: 0;
//    left: 0;
//    margin-top: 30px; // status bar height
//    width: 100%;
//    height: 100%;
//  }
//}

.overlayAfterOpen {
  //width: 100%;
  //height: 100%;
  //animation: fadeIn 0.4s;
  //width: 100%;
  //  height: 100%;
  //padding: 0;
}

.overlayBeforeClose {
  //animation: fadeOut 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}