@use "styles/colors";
@use "styles/config";

.appInfo {
  $stickyHeaderHeight: config.$app-info-header-height +
    config.$app-info-stats-height;

  > * {
    // Make sure all sections have a background so they will hide the cover photo under them
    background-color: colors.$background-medium;
  }

  .backBtn {
    background: none;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 9999;
  }

  .coverPhoto {
    height: calc(100vw * (9 / 16));
    background: colors.$cards-background no-repeat center center / cover;
    margin-bottom: -1rem;
    z-index: -1;
    position: sticky;
    top: 0;
  }

  .header {
    background: linear-gradient(
      180deg,
      colors.$background-dark 0,
      colors.$background-medium 100%
    );

    border-radius: 1.5rem 1.5rem 0 0;
    transition: border-radius 0.2s;

    &.top {
      border-radius: 0;
    }
  }

  .bodyContent {
    $wrappersHeight: $stickyHeaderHeight + config.$app-info-install-btn-height;
    min-height: calc(100vh - $wrappersHeight - $stickyHeaderHeight + 7.5rem);
  }

  .developerInfoField {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
